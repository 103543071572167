<template>
  <div id="select-trait" class="mt-2">
    <b-overlay :show="loader">
      <div class="container mb-3 ">
        <center>
          <h3 class="mb-3" for="">
            <strong class="text-danger my-3"></strong>WHAT IS THEIR CHARACTER
            TRAIT ?
          </h3>
          <span v-if="button" class="select-character-error-text text-danger"
            >*Please select a charecter trait</span
          >
        </center>
        <div class="modal-inner">
          <div class="row">
            <vue-select-image
              :h="'75px'"
              :w="'100px'"
              :selectedImages="[caricature_trait]"
              :dataImages="dataImages"
              @onselectimage="onSelectImage"
            >
            </vue-select-image>
          </div>
        </div>
        <div class="mt-3 mb-3" slot="modal-footer">
          <div class="row d-flex justify-content-between">
            <div class="col-md-4 ">
              <button class="btn btn-warning col-md-6" @click="prev">
                Previous
              </button>
            </div>
            <div class="col-md-4">
              <button class="btn btn-danger float-right col-md-6" @click="next">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <template #overlay>
        <div class="text-center">
          <img
            src="@/assets/site/images/Govava_Wait_icon.gif"
            alt=""
            class=""
          />
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import VueSelectImage from "vue-select-image";
require("vue-select-image/dist/vue-select-image.css");
export default {
  name: "SelectTrait",
  components: {
    VueSelectImage,
  },
  props: ["menu", "gender", "defaultTrait"],
  data() {
    return {
      traits: [],
      caricature_trait: null,
      loader: false,
      button: false,
      start_age: null,
      end_age: null,
      dataImages: null,
    };
  },
  watch: {
    defaultAge() {
      if (this.defaultTrait) this.caricature_trait = this.defaultTrait;
    },
  },
  created() {
    if (this.defaultTrait) this.caricature_trait = this.defaultTrait;
    this.getPersonas();
  },
  methods: {
    getPersonas() {
      this.loader = true;
      var fetch_url = process.env.VUE_APP_URL + "customer/persona/getPersonas";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          gender: this.gender,
          type: this.menu.label,
          ch_type: "character_trait",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.traits = data.caricatures;
          this.getImageArray(this.traits);
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    getImageArray(traits) {
      var img_array = new Array();

      traits.forEach(function(trait) {
        img_array.push({
          id: trait.id,
          src: trait.icon_name,
          alt: "",
        });
      });
      this.dataImages = img_array;
    },
    onSelectImage(image) {
      this.caricature_trait = image;
    },
    next() {
      if (this.caricature_trait == null) {
        this.button = true;
      } else {
        this.$emit("traitToPersona", this.caricature_trait);
      }
    },
    prev() {
      this.$emit("traitToAgeRange");
    },
  },
};
</script>

<style>
.persona-wrapper label {
  text-transform: capitalize;
}

.select-character-error-text {
  display: block;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
}
</style>
