<template>
  <b-modal
    id="myPagePopup"
    class="modal-dialog modal-dialog-centered modal-lg"
    scrollable
    ref="my-modal"
    hide-footer
    size="lg"
    style=" z-index: 1500 !important;"
    @show="checkData"
    @close="resetAll"
  >
    <b-overlay :show="loader" rounded="sm">
      <section>
        <select-category
          :categoryToAgeRange="categoryToAgeRange"
          :defaultCategory="menu"
          v-if="showCategory"
        ></select-category>
        <age-range
          v-bind:menu="menu"
          v-if="showAge"
          :defaultAge="age"
          v-on:agerangeToTrait="agerangeToTrait"
          v-on:ageRangeToCategory="ageRangeToCategory"
        ></age-range>
        <select-persona
          v-bind:gender="gender"
          v-bind:menu="menu"
          v-if="showPersona"
          :defaultPersona="personas"
          v-on:personaToTrait="personaToTrait"
          v-on:showPersonaToLifestyle="showPersonaToLifestyle"
        ></select-persona>
        <select-style
          v-bind:gender="gender"
          v-bind:menu="menu"
          v-if="showStyle"
          :defaultLifestyle="active_lifestyles"
          v-on:styleToPersona="styleToPersona"
          v-on:getWizardProducts="getWizardProducts"
        ></select-style>
        <select-trait
          v-bind:gender="gender"
          v-bind:age="age"
          v-bind:menu="menu"
          v-if="showTrait"
          :defaultTrait="caricature_trait"
          v-on:traitToPersona="traitToPersona"
          v-on:traitToAgeRange="traitToAgeRange"
        ></select-trait>
        <div class="px-4 mb-4 py-2">
          <strong class="text-dark mt-1" v-if="gender != null">
            {{ gender }} |
          </strong>
          <strong class="text-dark mt-1" v-if="age != null">
            {{ age.age_label }} |
          </strong>
          <strong class="text-dark mt-1" v-if="caricature_trait != null">
            <img :src="caricature_trait.src" class="avatar-img" alt="" />
          </strong>
        </div>
        <div class="form-row">
          <div class="col-md-6">
            <strong>
              <center v-if="personas != null">PERSONA</center>
            </strong>
            <div class="remove-img-bg selected-persona-wrapper">
              <vue-select-image
                :dataImages="personas"
                :h="'65px'"
                :w="'100px'"
                :limit="3"
                useLabel
                class="d-flex justify-content-center"
              >
              </vue-select-image>
            </div>
          </div>
          <div class="col-md-6">
            <strong
              ><center v-if="active_lifestyles != null">
                ACTIVE LIFESTYLE
              </center></strong
            >
            <div class="remove-img-bg selected-persona-wrapper">
              <vue-select-image
                :dataImages="active_lifestyles"
                :h="'65px'"
                :w="'100px'"
                :limit="3"
                useLabel
                class="d-flex justify-content-center"
              >
              </vue-select-image>
            </div>
          </div>
        </div>
      </section>
      <template #overlay>
        <div class="text-center">
          <img
            src="@/assets/site/images/Govava_Wait_icon.gif"
            alt=""
            class=""
          />
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
import SelectCategory from "@/views/site/mypage/SelectCategory";
import AgeRange from "@/views/site/mypage/AgeRange";
import SelectPersona from "@/views/site/mypage/SelectPersona";
import SelectStyle from "@/views/site/mypage/SelectStyle";
import SelectTrait from "@/views/site/mypage/SelectTrait";

import VueSelectImage from "vue-select-image";
require("vue-select-image/dist/vue-select-image.css");

export default {
  name: "MyPagePopup",
  props: ["modal_id", "getProfile", "userId", "customerId", "wizardDefaults"],
  data() {
    return {
      errors: [],
      error_msg: null,
      loader: false,
      menu: null,
      age: null,
      gender: null,
      personas: null,
      active_lifestyles: null,
      caricature_trait: null,
      persona_array: null,
      lifestyle_array: null,
      wizard_Details: null,
      wizard_data: null,
      showAge: false,
      showCategory: false,
      showGender: false,
      showPersona: false,
      toggleModal: false,
      showStyle: false,
      showTrait: false,
    };
  },
  components: {
    SelectCategory,
    AgeRange,
    SelectPersona,
    SelectStyle,
    SelectTrait,
    VueSelectImage,
  },
  watch: {
    wizardDefaults() {
      this.checkData();
    },
    menu(value) {
      if (value.id !== this.wizardDefaults?.menu?.id) {
        this.age = null;
        this.caricature_trait = null;
        this.personas = null;
        this.active_lifestyles = null;
      }
    },
  },
  methods: {
    getPersonas(menu, defaultPersonas) {
      this.loader = true;
      var fetch_url = process.env.VUE_APP_URL + "customer/persona/getPersonas";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          gender: this.gender,
          type: menu.label,
          ch_type: "persona",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          const personas = data.caricatures.filter((caricature) =>
            defaultPersonas.map((persona) => persona.id).includes(caricature.id)
          );
          const img_array = [];
          personas.forEach(function(persona) {
            img_array.push({
              id: persona.id,
              src: persona.icon_persona,
              alt: persona.name,
            });
          });
          this.personas = img_array;
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    getLifeStyles(menu, defaultLifeStyles) {
      this.loader = true;
      var fetch_url = process.env.VUE_APP_URL + "customer/persona/getPersonas";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          gender: this.gender,
          type: menu.label,
          ch_type: "active_lifestyle",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          const styles = data.caricatures.filter((caricature) =>
            defaultLifeStyles.map((style) => style.id).includes(caricature.id)
          );
          const img_array = [];
          styles.forEach(function(style) {
            img_array.push({
              id: style.id,
              src: style.icon_style,
              alt: style.name,
            });
          });
          this.active_lifestyles = img_array;
        });
    },
    checkData() {
      if (this.wizardDefaults) {
        this.personas = null;
        this.active_lifestyles = null;
        this.getPersonas(
          this.wizardDefaults.menu,
          this.wizardDefaults.personas
        );
        this.getLifeStyles(
          this.wizardDefaults.menu,
          this.wizardDefaults.activeLifestyle
        );

        this.gender = this.wizardDefaults.gender;
        this.menu = this.wizardDefaults.menu;
        this.age = this.wizardDefaults.age;
        this.caricature_trait = {
          id: this.wizardDefaults.caricature_trait.id,
          src: this.wizardDefaults.caricature_trait.icon_name,
          alt: "",
        };
      } else this.resetAll();

      this.showAge = false;
      this.showGender = false;
      this.showPersona = false;
      this.showStyle = false;
      this.showTrait = false;
      this.showCategory = true;
    },
    getWizardProducts(active_lifestyle) {
      this.active_lifestyles = active_lifestyle;
      this.loader = true;
      if (this.menu.persona_status) {
        this.getPersonaArray();
      }
      if (this.menu.active_lifestatus) {
        this.getLifeStyleArray();
      }
      if (this.gender == "Male") {
        var searchText = this.age.male_search_text;
      } else {
        var searchText = this.age.female_search_text;
      }
      const wizardData = {
        ageGroup: this.menu.label,
        gender: this.gender,
        ageRange: this.age.id,
        characterTrait: this.caricature_trait.id,
        activeLifeStyle: this.lifestyle_array,
        persona: this.persona_array,
        user_id: this.customerId,
        country: null,
        searchText: searchText,
      };

      var fetch_url = process.env.VUE_APP_URL + "customer/UpdateCustomerData";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          id: this.userId,
          new_wizard_details: wizardData,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.getProfile();
          this.closeModal();
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    categoryToAgeRange(menu) {
      this.menu = menu;
      if (menu.wizard_decrypt) {
        if (menu.wizard_decrypt.gender != "Both") {
          this.gender = this.menu.wizard_decrypt.gender;
        }
      }
      this.showCategory = false;
      this.showAge = true;
    },
    getPersonaArray() {
      var img_array = new Array();

      this.personas.forEach(function(persona) {
        img_array.push(persona.id);
      });
      this.persona_array = img_array;
    },
    getLifeStyleArray() {
      var img_array = new Array();

      this.active_lifestyles.forEach(function(style) {
        img_array.push(style.id);
      });

      this.lifestyle_array = img_array;
    },
    traitToPersona(caricature_trait) {
      this.caricature_trait = caricature_trait;
      this.showTrait = false;
      this.showPersona = true;
    },
    agerangeToTrait(age) {
      this.age = age;
      this.showAge = false;
      this.showTrait = true;
    },
    traitToAgeRange() {
      this.showAge = true;
      this.showTrait = false;
    },
    showPersonaToLifestyle(persona) {
      this.personas = persona;
      if (this.menu == "under_fifty") {
        this.showPersona = false;
        this.showStyle = true;
      } else {
        if (this.menu.active_lifestatus) {
          this.showPersona = false;
          this.showStyle = true;
        } else {
          this.showPersona = false;
          this.styleToPrice(null);
        }
      }
    },
    ageRangeToCategory() {
      this.showAge = false;
      this.showCategory = true;
    },
    personaToTrait() {
      this.showPersona = false;
      this.showTrait = true;
    },
    styleToPersona() {
      this.showPersona = true;
      this.showStyle = false;
    },
    closeModal() {
      this.$bvModal.hide("myPagePopup");
    },
    resetAll() {
      this.age = null;
      this.personas = null;
      this.active_lifestyles = null;
      this.caricature_trait = null;
      this.showAge = false;
      this.showGender = false;
      this.showPersona = false;
      this.showStyle = false;
      this.showTrait = false;
    },
  },
};
</script>

<style>
.remove-img-bg .vue-select-image__thumbnail--selected {
  background: none !important;
}

.remove-img-bg .vue-select-image__thumbnail {
  cursor: initial;
}
</style>
